import Link from 'next/link';
import { Dispatch, SetStateAction } from 'react';
import { routeNames } from '@/constants/routes';
import { CrossIcon } from '@/images/svg';
import { SyberryLogo } from '@/atoms/03-decorations/SyberryLogo';

export const MobileMenuHeader = ({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <div className="border-b-[1px] border-grey-1">
      <div className="flex flex-row justify-between py-base pl-base pr-xs">
        <Link
          scroll={true}
          prefetch={false}
          href={`${routeNames.home}`}
          data-testid="header-logo-mobile"
          className="block py-[4px]"
          aria-label="Syberry"
        >
          <span className="w-[113px] h-[32px] flex items-center">
            <SyberryLogo color="black" />
          </span>
        </Link>
        <button
          className="w-[40px] h-[40px] flex items-center justify-center"
          data-testid="cross-icon"
          onClick={() => setOpen(false)}
          title="Close"
          aria-label="Close navigation bar"
        >
          <CrossIcon />
        </button>
      </div>
    </div>
  );
};
