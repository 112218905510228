/* eslint-disable max-len */
import { IFooterColumn, FooterContact, INavigationLink } from '@/interfaces/navigation';
import { routeNames } from '@/constants/routes';

export const SYBERRY_DOMAIN = 'https://syberry.com';
export const AMOUNT_OF_VISIBLE_TAGS = 3;

export const navTags: INavigationLink[] = [
  {
    url: routeNames.whatWeDo,
    label: 'Services',
  },
  {
    url: routeNames.ourWork,
    label: 'Projects',
  },
];

export const viewAllNavTag = {
  url: '#industries',
  label: 'View all',
};

export const mobileNavLinks = [
  {
    id: 'industries',
    name: 'Industries',
    href: null,
    submenu: [
      {
        id: 'industries-finance',
        name: 'Finance',
        href: routeNames.finance,
      },
      {
        id: 'industries-healthcare',
        name: 'Healthcare',
        href: routeNames.healthcare,
      },
      {
        id: 'industries-utility',
        name: 'Utility',
        href: routeNames.energy,
      },
      {
        id: 'industries-startups',
        name: 'Startups',
        href: routeNames.startups,
      },
      {
        id: 'industries-technology',
        name: 'Technology',
        href: routeNames.technology,
      },
      {
        id: 'industries-education',
        name: 'Education',
        href: routeNames.education,
      },
      {
        id: 'industries-automotive',
        name: 'Automotive',
        href: routeNames.automotive,
      },
      {
        id: 'industries-construction',
        name: 'Construction',
        href: routeNames.construction,
      },
      {
        id: 'industries-compliance',
        name: 'Compliance',
        href: routeNames.compliance,
      },
      {
        id: 'industries-erp',
        name: 'ERP',
        href: routeNames.erp,
      },
    ],
  },
  {
    id: 'services',
    name: 'Services',
    href: null,
    submenu: [
      {
        id: 'services',
        name: 'About services',
        href: routeNames.whatWeDo,
        customRegExp: `^\\${routeNames.whatWeDo}\\/?$`,
      },
      {
        id: 'services-custom-software-development',
        name: 'Custom software development',
        href: routeNames.servicesCSD,
      },
      {
        id: 'services-legacy-software-modernization',
        name: 'Legacy software modernization',
        href: routeNames.servicesLSM,
      },
    ],
  },
  {
    id: 'our-work',
    name: 'Projects',
    href: routeNames.ourWork,
    submenu: null,
    relatedLinks: ['case-studies'],
  },
  {
    id: 'insights',
    name: 'Insights',
    href: routeNames.insights,
    submenu: null,
    relatedLinks: ['blog', 'events'],
  },
  {
    id: 'careers',
    name: 'Careers',
    href: null,
    submenu: [
      {
        id: 'careers-open-positions',
        name: 'Open positions',
        href: routeNames.careers,
      },
      {
        id: 'careers-work-at-syberry',
        name: 'Working at Syberry',
        href: null,
      },
    ],
  },
  {
    id: 'about-us',
    name: 'About us',
    href: routeNames.aboutUs,
    submenu: null,
  },
];

export const navItems: INavigationLink[] = [
  {
    url: routeNames.insights,
    label: 'Insights',
  },
  {
    url: routeNames.careers,
    label: 'Careers',
  },
  {
    url: routeNames.aboutUs,
    label: 'About us',
  },
];

export const contactUsLink = {
  url: routeNames.contact,
  label: 'Contact us',
};

export const footerConfig: IFooterColumn[] = [
  {
    name: 'About',
    labels: [
      {
        name: 'Projects',
        url: routeNames.ourWork,
        relatedLinks: ['case-studies'],
      },
      {
        name: 'About us',
        url: routeNames.aboutUs,
      },
      {
        name: 'Insights',
        url: routeNames.insights,
        relatedLinks: ['blog', 'events'],
      },
      {
        name: 'Contact us',
        url: routeNames.contact,
      },
    ],
  },
  {
    name: 'Industries',
    labels: [
      {
        name: 'Finance',
        url: routeNames.finance,
      },
      {
        name: 'Healthcare',
        url: routeNames.healthcare,
      },
      {
        name: 'Utility',
        url: routeNames.energy,
      },
      {
        name: 'Startups',
        url: routeNames.startups,
      },
      {
        name: 'Technology',
        url: routeNames.technology,
      },
      {
        name: 'Education',
        url: routeNames.education,
      },
      {
        name: 'Automotive',
        url: routeNames.automotive,
      },
      {
        name: 'Construction',
        url: routeNames.construction,
      },
      {
        name: 'Compliance',
        url: routeNames.compliance,
      },
      {
        name: 'ERP',
        url: routeNames.erp,
      },
    ],
  },
  {
    name: 'Services',
    labels: [
      {
        name: 'About services',
        url: routeNames.whatWeDo,
        customRegExp: `^\\${routeNames.whatWeDo}\\/?$`,
      },
      {
        name: 'Custom software development',
        url: routeNames.servicesCSD,
      },
      {
        name: 'Legacy software modernization',
        url: routeNames.servicesLSM,
      },
    ],
  },
  {
    name: 'Careers',
    labels: [
      {
        name: 'Open positions',
        url: routeNames.careers,
      },
    ],
  },
];

export const footerContact: FooterContact = {
  labels: [
    {
      label: 'Head office',
      fields: [
        {
          name: '5910 Courtyard Dr. STE 105 <br/> Austin TX 78731',
          url: 'https://www.google.com/maps/search/?api=1&query=30.356931838998285,-97.79109633194791',
          additionalProps: {
            className: 'transition text-white hover:text-white50 active:text-primary',
            ['data-testid']: 'footer-office-link',
            ['target']: '_blank',
          },
        },
      ],
    },
    {
      label: 'Contact',
      fields: [
        {
          name: '(512) 400-0056',
          url: 'tel:+15124000056',
          additionalProps: {
            className: 'tel-from-btn transition text-white hover:text-white50 active:text-primary',
            ['data-ctm-tracked']: '1',
            ['data-ctm-watch-id']: '2',
            ['data-testid']: 'footer-phone-link',
          },
        },
        { name: 'Mon-Fri from 9am to 5pm CT' },
      ],
    },
  ],
  email: 'contact@syberry.com',
  socials: [
    {
      icon: 'linkedIn',
      url: 'https://www.linkedin.com/company/syberry',
    },
  ],
  reviews: [
    {
      icon: 'clutch',
      url: 'https://clutch.co/profile/syberry',
      reviewsCount: 13,
    },
    {
      icon: 'google',
      url: 'https://www.google.com/search?hl=en-US&ie=UTF-8&q=syberry+corporation+austin#lrd=0x8644cb7865103e07:0xa9bedf363cd1a830,1,,,&wptab=s:H4sIAAAAAAAAAONgVuLVT9c3NEwyTzYzLzTIecRowS3w8sc9YSn9SWtOXmPU5OIKzsgvd80rySypFJLmYoOyBKX4uVB18uxi4vfJT07MCcjPDEoty0wtL17Eyl9cmZRaVFSpUAQRAQCzDb10cAAAAA',
      reviewsCount: 20,
    },
  ],
};

export const TABS_MODE = {
  outline: 'outline',
  select: 'select',
};

export const isActiveMobileLink = (pathname: string, href: string, relatedLinks?: string[]): boolean => {
  const isRelatedLink = relatedLinks?.some((relatedLink) => pathname.includes(relatedLink));

  return Boolean(pathname.startsWith(href) || isRelatedLink);
};
