import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { VISITOR_ID_KEY } from '@/constants/vacancies';

export const useVisitorId = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const visitorId = localStorage.getItem(VISITOR_ID_KEY);

    if (!visitorId) {
      const newVisitorId = uuidv4();
      localStorage.setItem(VISITOR_ID_KEY, newVisitorId);
    }
  }, []);
};
