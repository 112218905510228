'use client';

import Link from 'next/link';
import { COOKIES_CONSENT_ALIASES, COOKIES_CONSENT_KEY } from '@/constants/cookiesConsent';
import { useEffect, useState } from 'react';
import { HotjarScript } from '@/components/Scripts/Hotjar';
import { GoogleAnalyticsScript } from '@/components/Scripts/GoogleAnalytics';
import { GoogleTagManagerScript } from '@/components/Scripts/GoogleTagManager';

export const CookiesBanner = () => {
  const [givenConsent, setGivenConsent] = useState<COOKIES_CONSENT_ALIASES | null>(COOKIES_CONSENT_ALIASES.NO);

  useEffect(() => {
    setGivenConsent(localStorage.getItem(COOKIES_CONSENT_KEY) as COOKIES_CONSENT_ALIASES);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem(COOKIES_CONSENT_KEY, COOKIES_CONSENT_ALIASES.YES);
    setGivenConsent(COOKIES_CONSENT_ALIASES.YES);
  };

  const handleDeclineCookies = () => {
    localStorage.setItem(COOKIES_CONSENT_KEY, COOKIES_CONSENT_ALIASES.NO);
    setGivenConsent(COOKIES_CONSENT_ALIASES.NO);
  };

  return (
    <>
      {!givenConsent && (
        <div className="fixed bottom-0 p-[20px] bg-transparent z-[10000] w-full flex justify-center bg-red-600">
          <div className="bg-white bg-linear-gradient-purple p-[20px] w-full rounded-[16px] shadow-md max-w-[1376px]">
            <h3 className="text-[22px] leading-[26.4px] mb-[12px] text-black-9">We value your privacy</h3>
            <div className="flex flex-col md-tablet:flex-row md-tablet:items-center gap-[20px]">
              <p className="text-[14px] leading-[20px] text-black-9">
                By clicking &quot;accept all cookies&quot;, you agree to the storing of optional cookies on your device
                to enhance site navigation, analyze site usage, and assist in our marketing efforts. You can change{' '}
                {/* eslint-disable-next-line max-len */}
                cookie preferences at any time by adjusting your browser settings. You can find more information in our{' '}
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  className="font-bold text-purple-8 underline hover:text-purple-8 underline-offset-4"
                >
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link
                  href="/cookie-policy/"
                  target="_blank"
                  className="font-bold text-purple-8 underline hover:text-purple-8 underline-offset-4"
                >
                  Cookies Policy
                </Link>
                .
              </p>
              <div className="flex gap-[12px] justify-end">
                <button
                  className="
                    transition duration-300 w-[96px] h-[40px] bg-white
                    px-[14px] hover:border-purple-5 cursor-pointer
                    active:scale-[0.96] py-[10px] rounded-full border
                    border-black-4 text-[14px] font-bold leading-[17px] text-black-11
                  "
                  onClick={handleDeclineCookies}
                >
                  Reject all
                </button>
                <button
                  className="
                    transition duration-300 w-[102px] h-[40px]
                    px-[14px] py-[10px] hover:border-purple-5 hover:bg-purple-9
                    cursor-pointer active:scale-[0.96] rounded-full border
                    border-purple-8 bg-purple-8 text-[14px] font-bold leading-[17px] text-white
                  "
                  onClick={handleAcceptCookies}
                >
                  Accept all
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {givenConsent === COOKIES_CONSENT_ALIASES.YES && (
        <>
          <HotjarScript />
          <GoogleAnalyticsScript />
          <GoogleTagManagerScript />
        </>
      )}
    </>
  );
};
