'use client';

import { footerConfig, isActiveMobileLink } from '@/constants/navigations';
import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { usePathname, useRouter, useSelectedLayoutSegments } from 'next/navigation';
import { getRouteSegments, is404 } from '@/helpers/darkMode';
import { SyberryLogo } from '@/atoms/03-decorations/SyberryLogo';
import { cn } from '@/helpers/tailwind';
import { useCallback } from 'react';

export const Navigation = () => {
  const pathname = usePathname();
  const router = useRouter();
  const segments = useSelectedLayoutSegments();

  //TODO: Should be refactored
  const getActiveNavItemClass = useCallback(
    (href: string, relatedLinks?: string[], customRegExp?: string) => {
      const pathnameProcessor = () => {
        if (customRegExp) {
          const regExp = new RegExp(customRegExp);

          return regExp.test(pathname);
        }

        return pathname.startsWith(href);
      };

      if (relatedLinks && isActiveMobileLink(pathname, href, relatedLinks)) {
        return 'text-purple-7';
      }

      return pathnameProcessor() ? 'text-purple-7' : 'text-white';
    },
    [pathname],
  );

  return (
    <div className="w-full flex flex-col justify-end">
      {is404(segments, getRouteSegments(pathname)) ? (
        <span
          data-testid="footer-logo"
          className="mb-[32px] hidden mobile:flex cursor-pointer w-[113px] h-[32px] items-center"
          onClick={() => router.replace(routeNames.home)}
          aria-label="Syberry"
        >
          <SyberryLogo />
        </span>
      ) : (
        <Link
          scroll={true}
          href={routeNames.home}
          data-testid="footer-logo"
          className="mb-[32px] block mobile:hidden"
          aria-label="Syberry"
        >
          <span className="w-[113px] h-[32px] flex items-center">
            <SyberryLogo />
          </span>
        </Link>
      )}

      <ul className="flex flex-wrap gap-y-[32px] gap-x-[50px]">
        {footerConfig.map(({ name, labels }) => {
          return (
            <li
              key={name}
              className="flex-1"
            >
              <p className="mb-[19px] text-white leading-[25.6px]">{name}</p>
              <ul>
                {labels.map(({ name, url, relatedLinks, customRegExp }) => {
                  return (
                    <li
                      key={name}
                      className="mb-[16px] last:mb-0"
                    >
                      <a
                        href={url || ''}
                        className={cn(
                          `whitespace-nowrap hover:text-white/50 active:text-primary 
                          font-semibold text-sm transition text-white`,
                          `${getActiveNavItemClass(url || '', relatedLinks, customRegExp)}`,
                        )}
                      >
                        {name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
