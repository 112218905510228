/* eslint-disable max-len */

import { ISyberryLogoProps } from '@/atoms/03-decorations/SyberryLogo/index.types';
import './index.styles.scss';

export const SyberryLogo = ({ color = 'white' }: ISyberryLogoProps) => {
  return (
    <svg
      width="113"
      height="26"
      viewBox="0 0 113 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="syberry-logo w-full h-auto"
      aria-hidden={true}
    >
      <path
        d="M24.5055 25.0944L31.8583 20.8498V9.53125L24.5055 13.7759V25.0944Z"
        fill="#A7337B"
      />

      <path
        d="M17.1542 18.0232L9.8028 22.2679L0 16.6079L7.3514 12.3633L17.1542 18.0232Z"
        fill="#56E881"
      />

      <path
        d="M7.3514 1.04297V12.3615L0 16.6061V5.2876L7.3514 1.04297Z"
        fill="#2BBD57"
      />

      <path
        d="M14.7042 8.11572L22.0556 3.87109L31.8584 9.53106L24.5056 13.7757L14.7042 8.11572Z"
        fill="#C93D94"
      />

      <path
        d="M24.5055 25.0944L31.8583 20.8498V9.53125L24.5055 13.7759V25.0944Z"
        className="fill-white opacity-0 animated animated-1"
      />

      <path
        d="M17.1542 18.0232L9.8028 22.2679L0 16.6079L7.3514 12.3633L17.1542 18.0232Z"
        className="fill-white opacity-0 animated animated-2"
      />

      <path
        d="M7.3514 1.04297V12.3615L0 16.6061V5.2876L7.3514 1.04297Z"
        className="fill-white opacity-0 animated animated-3"
      />

      <path
        d="M14.7042 8.11572L22.0556 3.87109L31.8584 9.53106L24.5056 13.7757L14.7042 8.11572Z"
        className="fill-white opacity-0 animated animated-4"
      />

      <path
        d="M43.8371 18.2124C42.8554 18.2124 41.9922 18.045 41.2462 17.7118C40.5001 17.3785 39.9479 16.8807 39.5868 16.2197C39.227 15.5587 39.1252 14.7569 39.2828 13.8142H42.4231C42.345 14.2075 42.3771 14.5477 42.5207 14.835C42.6643 15.1236 42.8833 15.3454 43.1789 15.5029C43.4731 15.6605 43.8231 15.7386 44.2289 15.7386C44.6347 15.7386 44.9917 15.6703 45.2985 15.5322C45.6053 15.3942 45.8451 15.2017 46.0152 14.9535C46.1854 14.7053 46.283 14.4041 46.3095 14.0499C46.3095 13.8142 46.2607 13.6023 46.1617 13.4112C46.064 13.2216 45.919 13.0515 45.7294 12.9009C45.5397 12.7503 45.3138 12.6136 45.0517 12.4881C44.7895 12.364 44.5078 12.2524 44.208 12.1548C43.9068 12.0572 43.6 11.9429 43.2849 11.8118C42.3562 11.498 41.6464 11.0588 41.1555 10.4968C40.6647 9.93484 40.4513 9.22784 40.5169 8.37722C40.5434 7.5001 40.8013 6.73175 41.2922 6.07078C41.783 5.4098 42.4343 4.89943 43.2458 4.53966C44.0574 4.17989 44.9736 4 45.9943 4C46.923 4 47.7179 4.15339 48.3789 4.46157C49.0398 4.76974 49.5335 5.22713 49.8612 5.83511C50.1875 6.4431 50.299 7.17938 50.1944 8.04394H47.0346C47.0736 7.73019 47.0374 7.4513 46.9272 7.21005C46.8157 6.96742 46.6358 6.78474 46.3876 6.66064C46.1393 6.53653 45.8381 6.47378 45.484 6.47378C45.1298 6.47378 44.8397 6.52956 44.5706 6.64111C44.3028 6.75267 44.0895 6.9228 43.9319 7.15149C43.7743 7.38018 43.6893 7.64513 43.6767 7.94633C43.6502 8.16945 43.6837 8.36188 43.7743 8.52503C43.8664 8.68819 43.9863 8.83321 44.1369 8.95732C44.2875 9.08142 44.4744 9.19995 44.6961 9.31012C44.9178 9.42167 45.1674 9.52626 45.4421 9.62387C45.7168 9.72148 45.9985 9.82328 46.2858 9.92786C46.7432 10.098 47.1685 10.2904 47.5617 10.5066C47.9549 10.7227 48.3008 10.9807 48.602 11.2819C48.9032 11.5831 49.1347 11.9359 49.2992 12.3417C49.4624 12.7475 49.5251 13.2049 49.4861 13.7152C49.447 14.6049 49.1849 15.3872 48.701 16.0607C48.2171 16.7342 47.5561 17.2613 46.718 17.6406C45.88 18.0199 44.9192 18.2096 43.8329 18.2096L43.8371 18.2124Z"
        fill={color}
      />
      <path
        d="M51.0857 22.2944L53.9583 17.3134L53.222 17.3078L51.0425 8.08203H54.2219L55.6135 14.4436L59.2656 8.08203H62.406L54.1856 22.2944H51.0843H51.0857Z"
        fill={color}
      />
      <path
        d="M67.8119 18.2123C67.3406 18.2123 66.9222 18.1537 66.5555 18.0352C66.1887 17.9181 65.875 17.7479 65.6128 17.5248C65.3507 17.3031 65.1415 17.0674 64.9853 16.8178L64.4359 17.9766H61.845L64.3383 3.84375H67.2834L66.3212 9.24172C66.7144 8.83593 67.1788 8.50265 67.7157 8.24049C68.2525 7.97833 68.8675 7.84725 69.5605 7.84725C70.3721 7.84725 71.0749 8.04666 71.6704 8.44548C72.2658 8.84429 72.7204 9.38535 73.0341 10.0644C73.3479 10.7449 73.486 11.5175 73.4469 12.3807C73.4079 13.1657 73.2405 13.9146 72.9463 14.6285C72.6521 15.3425 72.2491 15.9672 71.7387 16.5027C71.2283 17.0396 70.6399 17.4579 69.9719 17.7591C69.304 18.0603 68.5844 18.2109 67.8119 18.2109V18.2123ZM67.5762 15.6409C68.0866 15.6409 68.5509 15.5168 68.9693 15.2686C69.3876 15.0204 69.7251 14.6801 69.9803 14.2478C70.2355 13.8156 70.3763 13.3191 70.4028 12.7558C70.4418 12.3235 70.3791 11.9288 70.2159 11.5677C70.0528 11.2079 69.8101 10.9262 69.4894 10.724C69.1687 10.5218 68.7852 10.42 68.3404 10.42C67.8175 10.42 67.3489 10.5483 66.9375 10.8035C66.5262 11.0587 66.1887 11.4059 65.9266 11.8438C65.6644 12.283 65.5277 12.7837 65.5138 13.3456C65.4873 13.8044 65.5556 14.206 65.7202 14.5532C65.8833 14.9004 66.1232 15.1682 66.4369 15.3578C66.7507 15.5475 67.13 15.6423 67.5762 15.6423V15.6409Z"
        fill={color}
      />
      <path
        d="M83.7535 9.89891C83.4328 9.26443 82.9517 8.76382 82.3102 8.39707C81.6688 8.03033 80.8976 7.84766 79.994 7.84766C79.0904 7.84766 78.2928 8.00105 77.5593 8.30922C76.8258 8.6174 76.1885 9.04271 75.6461 9.58516C75.1022 10.129 74.6741 10.7565 74.3604 11.4691C74.0466 12.183 73.8695 12.9514 73.8305 13.7755C73.7914 14.6526 73.942 15.428 74.2823 16.1015C74.6225 16.775 75.1232 17.2951 75.7841 17.6619C76.4451 18.0286 77.24 18.2113 78.1687 18.2113C78.9537 18.2113 79.69 18.0677 80.3775 17.779C81.065 17.4918 81.673 17.0916 82.2028 16.5812C82.7327 16.0708 83.1553 15.5088 83.469 14.8925H80.4458C80.2367 15.1672 79.9773 15.3903 79.6705 15.5604C79.3623 15.7306 78.9928 15.8156 78.5619 15.8156C78.1031 15.8156 77.728 15.718 77.4338 15.5214C77.1395 15.3248 76.936 15.0473 76.8258 14.6875C76.7393 14.4072 76.7101 14.0962 76.7393 13.7546H83.9027C83.9682 13.5189 84.0268 13.2568 84.0798 12.9695C84.1314 12.6823 84.1649 12.3936 84.1774 12.1063C84.2165 11.2697 84.0756 10.5334 83.7549 9.89752L83.7535 9.89891ZM81.2504 12.0283H77.0935C77.1828 11.7926 77.2916 11.5792 77.4226 11.3896C77.6973 10.9908 78.0209 10.6924 78.3946 10.4957C78.7669 10.2991 79.1629 10.2015 79.5827 10.2015C79.9494 10.2015 80.2632 10.2768 80.5253 10.4274C80.7875 10.578 80.9869 10.7872 81.1235 11.0549C81.2616 11.3227 81.3034 11.6476 81.2518 12.0269L81.2504 12.0283Z"
        fill={color}
      />
      <path
        d="M84.4562 17.977L86.184 8.08332H88.7944L88.7749 9.85011C89.1012 9.44432 89.4679 9.09152 89.8737 8.79031C90.2795 8.48911 90.7271 8.25763 91.218 8.09308C91.7088 7.92993 92.229 7.84766 92.7784 7.84766L92.229 10.9685H91.2277C90.8485 10.9685 90.4887 11.0117 90.1484 11.0968C89.8082 11.1818 89.507 11.3199 89.2462 11.5095C88.9841 11.6992 88.7623 11.9585 88.5783 12.2848C88.3942 12.6125 88.2645 13.0183 88.185 13.5022L87.3999 17.9784H84.4548L84.4562 17.977Z"
        fill={color}
      />
      <path
        d="M92.2015 17.977L93.9292 8.08332H96.5396L96.5201 9.85011C96.8464 9.44432 97.2132 9.09152 97.619 8.79031C98.0247 8.48911 98.4724 8.25763 98.9632 8.09308C99.4541 7.92993 99.9742 7.84766 100.524 7.84766L99.9742 10.9685H98.973C98.5937 10.9685 98.2339 11.0117 97.8937 11.0968C97.5534 11.1818 97.2522 11.3199 96.9914 11.5095C96.7293 11.6992 96.5076 11.9585 96.3235 12.2848C96.1394 12.6125 96.0097 13.0183 95.9303 13.5022L95.1452 17.9784H92.2001L92.2015 17.977Z"
        fill={color}
      />
      <path
        d="M100.588 22.2944L103.651 17.3078H103.062L100.884 8.08203H104.063L105.476 14.5398L109.107 8.08203H112.248L103.688 22.2944H100.587H100.588Z"
        fill={color}
      />
    </svg>
  );
};
