export const urls = {
  davinciBaseUrl: process.env.NEXT_PUBLIC_DAVINCI_BASE_URL || 'https://qa-kernel.davinci.jarvis.syberry.net/public',
};

export const VISITOR_ID_KEY = 'visitorId';

export enum sourcingOptionsAliases {
  ACTIVE_SOURCING = 'active_sourcing',
  PASSIVE_SOURCING = 'passive_sourcing',
}

export const sourcingOptions = {
  [sourcingOptionsAliases.ACTIVE_SOURCING]: 'ACTIVE',
  [sourcingOptionsAliases.PASSIVE_SOURCING]: 'PASSIVE',
};

export enum vacancyQueryParams {
  ID = 'id',
  SOURCE = 'source',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PERSONAL_EMAIL = 'personalEmail',
  PERSONAL_PHONE_NUMBER = 'personalPhoneNumber',
  SOURCE_NAME = 'sourceName',
}

export const countries = {
  PL: 'Poland',
  BY: 'Belarus',
  US: 'USA',
  GE: 'Georgia',
  Anywhere: 'Anywhere',
};

export const workplaces = {
  REMOTE_ONLY: 'Remote',
  OFFICE_ONLY: 'Office',
  HYBRID: 'Hybrid',
};

export const assignments = {
  PART_TIME: 'Part time',
  FULL_TIME: 'Full time',
};

export const contractTypes = {
  EC: 'Umowa o prace',
  CFS: 'Umowa o świadczenie usług',
  B2B: 'B2B contract',
};

export const relocationRequirements = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

// TODO: TEMPORARY

export const workplacesList = {
  REMOTE_ONLY: {
    value: 'Remote',
    icon: 'remote',
  },
  OFFICE_ONLY: {
    value: 'Office',
    icon: 'office',
  },
  HYBRID: {
    value: 'Hybrid',
    icon: 'hybrid',
  },
};

export const relocationRequirementsList = {
  REQUIRED: {
    value: 'REQUIRED',
    icon: 'required',
  },
  OPTIONAL: {
    value: 'OPTIONAL',
    icon: 'optional',
  },
  NOT_APPLICABLE: {
    value: 'NOT_APPLICABLE',
    icon: 'not-applicable',
  },
};
