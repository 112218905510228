'use client';

import clsx from 'clsx';
import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';
import { scroll } from 'seamless-scroll-polyfill';
import { useScreenSize } from '@/hooks/useScreenSize';
import {
  BUTTON_APPEAR_SCROLL_Y,
  SCROLL_BUTTON_BREAKPOINT,
  THROTTLE_DELAY,
} from '@/molecules/00-ui/ScrollToTopButton/index.constants';

export const ScrollToTopButton = () => {
  const { width } = useScreenSize();
  const isEnabled = width < SCROLL_BUTTON_BREAKPOINT;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isEnabled) return;

    const handleScroll = throttle(() => {
      setIsVisible(window.scrollY > BUTTON_APPEAR_SCROLL_Y);
    }, THROTTLE_DELAY);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isEnabled]);

  const scrollToTop = () => {
    scroll(window, { behavior: 'smooth', top: 0, left: 0 });
  };

  if (!isEnabled) return null;

  return (
    <div
      className={clsx(
        `
          fixed bottom-[16px] right-[16px] z-100 transition-transform 
          ease-bounce-appear duration-500 lg:hidden  
        `,
        {
          [`translate-x-0`]: isVisible,
          ['translate-x-[160%]']: !isVisible,
        },
      )}
    >
      <button
        type="button"
        aria-label="Scroll to top"
        onClick={scrollToTop}
        className="
          flex justify-center transition-scale duration-300 items-center w-[44px] h-[44px]
          rounded-full border-[1px] border-purple-9 backdrop-blur-[2px] bg-white/5
          active:bg-purple-8/20 active:scale-[0.9] shadow-lg
        "
      >
        <span
          className="
            block bg-[url(/icons/arrow.svg)] bg-center bg-no-repeat bg-contain w-[20px] h-[26px] rotate-[270deg]
          "
        />
      </button>
    </div>
  );
};
